window.__APP_VERSION__ = __WEBPACK_APP_VERSION__;
window.__APP_API__ = "<APP_API>";
window.__NEW_RELIC_LICENSE__ = "<NEW_RELIC_LICENSE>";
window.__NEW_RELIC_APPLICATION__ = "<NEW_RELIC_APPLICATION>";
window.__HOTJAR_KEY__ = "<HOTJAR_KEY>";
window.__HERE_KEY__ = "<HERE_KEY>";
window.__HOTJAR_SURVEY_KEY__ = "<HOTJAR_SURVEY_KEY>";
window.__ANALYTICS_KEY__ = "<ANALYTICS_KEY>";
window.__OLD_BROWSER__ = !Object.assign;
window.__ENVIRONMENT__ = "<ENVIRONMENT>";
window.__RECAPTCHA_DISABLED__ = "<RECAPTCHA_DISABLED>";
window.__RECAPTCHA_SITE_KEY__ = "<RECAPTCHA_SITE_KEY>";
window.__FRESHDESK_WEB_TOKEN__ = "<FRESHDESK_WEB_TOKEN>";
window.__FRESHDESK_SITE_ID__ = "<FRESHDESK_SITE_ID>";
window.__HUBSPOT_PORTAL_ID__ = "<HUBSPOT_PORTAL_ID>";
window.__HUBSPOT_FORM_ID__ = "<HUBSPOT_FORM_ID>";
window.__MAIN_PAGE_URL__ = "<MAIN_PAGE_URL>";
window.__CHAT_API__ = "<CHAT_API>";
